.groupsContainer{
  border-radius: 0.25rem;
  box-shadow: 0 0 1px rgba(0, 0, 0, 0.125), 0 1px 3px rgba(0, 0, 0, 0.2);
  padding: 1%;
  width : 1000px;
}

.topBar {
  display: flex;
  padding: 2px 10px 10px 2px;
  justify-content: space-between;
  align-items: center;
}

.clickable{
  cursor: pointer;
}

.groupsBody{
  display: flex;
  width: 100%;
  height: 70vh;
}

.columnHeader{
  font-weight: bold;
  margin-left: 5%;
  margin-right: 5%;
  border-bottom: 1px solid lightgray;
}

.rightPart{
  border : 1px solid #DDD;  
  border-top-right-radius: 0.25rem;
  border-bottom-right-radius: 0.25rem;
  width : 100%;
}

.midPart{
  border-top : 1px solid #DDD;  
  border-bottom : 1px solid #DDD;
  width : 100%;
}

.leftPart{
  border : 1px solid #DDD;
  border-top-left-radius: 0.25rem;
  border-bottom-left-radius: 0.25rem;
  width : 100%;
}

.groupElement{
  cursor: pointer;
  padding : 4px;
  margin : 4px;
  border-radius: 5px;
  display: flex;
  justify-content: space-between;
}

.userElement{
  cursor: pointer;
  padding : 4px;
  margin : 4px;
  background-color: lightgray;
  border-radius: 5px;
  display: flex;
  justify-content: space-between;
}

.memberElement{
  cursor: pointer;
  padding : 4px;
  margin : 4px;
  background-color: lightgray;
  border-radius: 5px;
  display: flex;
  justify-content: space-between;
}

.addNewGroup{
  display: flex;
  padding: 4px;
}

.smallButton{
  background-color: #333;
  color : white;
  border-radius: 8px;
  padding: 3px;
  width: 50px;
  text-align: center;
  font-size: small;
}

.smallButton:hover{
  color : orange;
}