.chartBox {
  min-width: 35vw;
  height: 250px;
  border-radius: 0.25rem;
  box-shadow: 0 0 1px rgba(0, 0, 0, 0.125), 0 1px 3px rgba(0, 0, 0, 0.2);
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
  align-items: center;
  color: white;
  margin-top: 2%;
  margin-bottom: 2%;
}
