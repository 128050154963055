.container{
  display: flex;
  justify-content: center;    
}

.container h2{
  color : #ff4f00;
  align-self: center;
}

.bikesList {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 1200px;
  border-radius: 0.25rem;
  box-shadow: 0 0 1px rgba(0, 0, 0, 0.125), 0 1px 3px rgba(0, 0, 0, 0.2);
  padding: 1%;
}

.clickable{
  cursor: pointer;
}

.editSectionContainer{
  display: flex;
  flex-direction: column;
  width: 1200px;
  border-radius: 0.25rem;
  box-shadow: 0 0 1px rgba(0, 0, 0, 0.125), 0 1px 3px rgba(0, 0, 0, 0.2);
  padding: 1%;  
}

.editSectionContainer table tr td{
  padding: 3px;
}

.bikeDetails{
  max-height: 75vh;
  overflow: auto;
  display: flex;
  flex-direction: row;
  width: 40vw;
}

.bikeDetailsBody{ 
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  align-items: flex-start;
  margin-top: 10px;
}

.bikeMapContainer{
  width: 90%;
  height: 45vh;
}

.bikeDetailsPlugins{
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  width: 40vw;
}

.bikeDetailsGaugeCharts{
  display: flex;
  flex-direction: row;
  justify-content: space-around;
}

.gaugeChartName{
  text-align: center;
}

.gaugeChartName p{
  margin: 0;
  padding: 10px;
  font-weight: bold;
}

.bikeDetailsButtonContainer {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.bikeDetailsButtonContainerPadding {
  padding: 2px 10px 10px 2px;
}

.bikeDetailsButtons {
  cursor: pointer;
  font-size: 1.1em;
  color: #1a3060;
  border: 1px solid #1a3060;
  padding: 6px;
  border-radius: 4px;
  transition: background-color 0.3s;
  margin-left: 5px;
}

.bikeDetailsButtons:hover {
  background-color: #1a3060;
  color: #eee;
}

.topBar {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  width: 100%;
}

.topBar div {
  text-align: center;
}

.topBar h3{
  color : #666;
}

.uppercase{
  text-transform:uppercase;
}