.container{
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
  }

.newPasswordContainer{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-top: 30px;
}

.inputContainer{
    min-width: 350px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    margin-top: 20px;
}

.saveNewPassword{
    margin-top:  25px;
}