.container {
  display: flex;
  justify-content: center;
  flex-direction: column;
}

.inputContainer {
  min-width: 350px;
  max-width: 500px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  margin-top: 20px;
}

.savePrices {
  margin-top: 5%;
}
