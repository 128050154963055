.listTable {
  border-collapse: collapse;
  margin: 1%;
  font-size: 0.9em;
  color: #666;
  width: 100%;
  border-bottom: 1px solid #333;
}

.listTable tr {
  border-bottom: 1px solid #EEE;
  width:100%;
  
}

.listTable thead tr {
  border-bottom: 1px solid #333;
  color: #555;
}

.listTable tr td {
  border-collapse: collapse;
  padding: 6px 6px;
}

.listTable tbody td{
  min-width: 28px;
  max-width: 220px;
  white-space: nowrap;
  text-align: left;
}

.listTable tbody tr:hover {
  background-color: #EEE;
}

.listTable th {
  text-align: left;
  padding-left: 8px;
  text-align: left;
}

.tableSection {
  width: 80%;
  display: flex;
  justify-content: center;
}

.listtable input, select{
  height: 2rem;
  max-height: 30px;
  min-height: 10px;
  box-sizing: border-box;
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  /* margin: 5px; */
}
