.container {
  padding: 6px 20px;
  display: flex;
  justify-content: space-between;
  position: sticky;
  top: 0;
  background-color: white;
  margin-bottom: 1.5%;
  box-shadow: 2px 2px 2px #ddd;
}

.header {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  align-items: center;
  padding-top: 8px;
}

.title {
  margin: 0;
  color: #dd5a2a;
  padding: 5px;
}

.userSection {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 10px;
}

.backToHome {
  margin: 3rem 0 0;
}

.searchBox {
  display: flex;
}

.searchBox input {
  width: 400px;
  height: 36px;
  padding: 4px;
  font-size: 1em;
  border: 1px solid lightgray;
  border-radius: 4px;
}

.searchbutton {
  background-color: rgb(255, 255, 255);
  border-radius: 4px;
  border: 1px solid gray;
  margin-left: 10px;
  cursor: pointer;
  font-size: 14px;
  transition: background-color 0.5s;
  width: 80px;
}

.searchbutton:hover {
  background-color: #eee;
}

.signInBox {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border: 1px solid gray;
  border-radius: 4px;
  width: 80px;
  height: 50px;
  cursor: pointer;
}

.loggedInUser {
  cursor: pointer;
  display: flex;
  flex-direction: row;
  align-items: center;
  border: 1px solid gray;
  border-radius: 4px;
}

.logoutIcon {
  cursor: pointer;
}

.logoutIcon:hover {
  color: red;
}

main {
  padding: 0px 20px;
}

.bgImg {
  position: fixed;
  opacity: 0.1;
  margin-left: auto;
  z-index: -10;
  height: 80vh;
  margin-top: 10%;
  display: none;
}

.container section {
  display: flex;
  gap: 6px;
  align-content: center;
  align-items: center;
  justify-content: center;
}
