.tabControl{
  width: 100%;
  display: flex;
  margin-top: 6px;
  font-size: 0.95em;
  border-bottom: 1px solid #DDD;
  height: 30px;
}

.tabs{
  width: 180px;
  border: 1px solid #DDD;
  padding: 6px 8px 0px 0px;
  cursor: pointer;
  background-color: #EEE;
  text-align: center;
  color : #333;
  height: 24px;
}

.tabs:hover{
  color :  #ff4f00;
}

.active{
  background-color: white;
  /*border-top : 5px solid  rgba(88,167,238,1) ;*/
  color :  #ff4f00;
  border-bottom: 1px solid transparent;
  /*transform: translateY(-4px);*/
  transition: background-color 0.3s;

}