.container {
  display: flex;
  justify-content: center;
}

.reportsList {
  display: flex;
  flex-direction: column;
  align-items: center;
  color: #ff4f00;
  width: 1200px;
  border-radius: 0.25rem;
  box-shadow: 0 0 1px rgba(0, 0, 0, 0.125), 0 1px 3px rgba(0, 0, 0, 0.2);
  padding: 1%;
}

.ExportBar {
  display: flex;
  flex-direction: row;
  gap: 1rem;
}

.topBar {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  width: 100%;
}

.editSectionContainer {
  display: flex;
  flex-direction: column;
  width: 1200px;
  height: 8vh;
}

.editSectionContainer table tr td {
  padding: 3px;
}

.uppercase {
  text-transform: uppercase;
}

.clickable {
  cursor: pointer;
}

.reportSelector {
  display: flex;
  margin: 0.5%;
}
