.container {
  display: flex;
  justify-content: center;
  border-radius: 0.25rem;
  box-shadow: 0 0 1px rgba(0, 0, 0, 0.125), 0 1px 3px rgba(0, 0, 0, 0.2);
  padding: 1%;
}

.bikesList {
  display: flex;
  flex-direction: column;
  align-items: center;
  color: #dd5a2a;
  width: 1200px;
}

.clickable {
  cursor: pointer;
}

.nonClickable {
  color: gray;
  text-decoration: line-through;
}

.editSectionContainer {
  display: flex;
  flex-direction: column;
  width: 1200px;
  height: 8vh;
}

.editSectionContainer table tr td {
  padding: 3px;
}

.bikeDetails {
  max-height: 75vh;
  overflow: auto;
  display: flex;
  flex-direction: row;
  width: 40vw;
}

.bikeDetailsBody {
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  align-items: flex-start;
  margin-top: 10px;
}

.bikeMapContainer {
  width: 70%;
  height: 80vh;
}

.bikeDetailsPlugins {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  width: 40vw;
}

.bikeDetailsGaugeCharts {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
}

.gaugeChartName {
  text-align: center;
}

.gaugeChartName p {
  margin: 0;
  padding: 10px;
  font-weight: bold;
}

.bikeDetailsButtonContainer {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.bikeDetailsButtonContainerPadding {
  padding: 2px 10px 10px 2px;
}

.bikeDetailsButtons {
  cursor: pointer;
  font-size: 1.1em;
  color: #1a3060;
  border: 1px solid #1a3060;
  padding: 6px;
  border-radius: 4px;
  transition: background-color 0.3s;
  margin-left: 5px;
}

.bikeDetailsButtons:hover {
  background-color: #1a3060;
  color: #eee;
}

.bikesTopBar {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  width: 100%;
}

.bikesTopBar div {
  text-align: center;
}

.uppercase {
  text-transform: uppercase;
}

.listBox {
  padding: 5px;
  border: 1px solid lightgray;
  margin-right: 5px;
  max-height: 80vh;
  overflow-y: auto;
  overflow-x: hidden;
}

.listBoxRight {
  padding: 5px;
  border: 1px solid lightgray;
  margin-left: 5px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  width: auto;
}

.focusListItem {
  font-weight: bold;
}

.showAllButton {
  font-size: 0.8em;
}

.addNewStationButton {
  font-size: 0.8em;
  margin-bottom: 5px;
}

.zoneContainer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  min-height: 4vh;
}

.inputContainer {
  width: 8vw;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  transition: 500ms;
  margin-top: 15px;
}

.inputBox {
  width: 95%;
  margin-bottom: 5px;
}

.noDisplay {
  display: none;
  /* background-color: red; */
}

.display {
  display: flex;
}

.bikeControlButtons {
  display: flex;
  flex-direction: column;
  gap: 4px;
  margin-top: 4px;
  margin-bottom: 4px;
}
