.toast{
  width: 400px;
  min-height: 160px;
  position: fixed;
  top : 30%;
  left : calc(50vw - 200px);
  border : 1px solid #DDD;
  border-radius: 10px;
  background-color: white;
  padding: 12px 10px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  z-index: 1000;
}

.backdrop{
  width: 100vw;
  height: 100vh;
  position: absolute;
  top : 0px;
  left: 0px;
  background-color: rgba(0,0,0,0.1);
}

.toast p{
  margin: 0;
  padding: 0;
}

.title{
  font-weight: bold;
}