.container {
  display: flex;
  justify-content: center;
}

.container h2 {
  color: #ff4f00;
  align-self: center;
}

.topBar {
  display: flex;
  padding: 2px 10px 10px 2px;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}

.topBar h3 {
  color: #666;
}

.usersList {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 1200px;
  border-radius: 0.25rem;
  box-shadow: 0 0 1px rgba(0, 0, 0, 0.125), 0 1px 3px rgba(0, 0, 0, 0.2);
  padding: 1%;
}

.usersList th td {
  text-align: left;
}

.usersList table tr td:first-child {
  width: 16px;
}

.usersList table thead tr th:first-child {
  width: 16px;
}

.userEditForm {
  display: flex;
  flex-direction: column;
  width: 400px;
  font-size: 1em;
  margin: 0 auto;
}

.userEditForm input {
  padding: 6px;
  border: 1px solid lightgray;
  border-radius: 4px;
}

.userEditForm label {
  font-size: 0.8em;
}

.userEditForm button {
  margin: 0.5rem;
  padding: 8px 16px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-size: 0.8em;
  color: #515151;
  width: 100px;
}

.userDetails {
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 800px;
  border-radius: 0.25rem;
  box-shadow: 0 0 1px rgba(0, 0, 0, 0.125), 0 1px 3px rgba(0, 0, 0, 0.2);
  padding: 1%;
}

.userDetailsButtons:hover {
  background-color: #1a3060;
  color: #eee;
}

.clickable {
  cursor: pointer;
}

.groupsBody {
  display: flex;
  width: 100%;
  min-width: 900px;
  height: 70vh;
}

.columnHeader {
  font-weight: bold;
  margin-left: 5%;
  margin-right: 5%;
  border-bottom: 1px solid lightgray;
}

.rightPart {
  border: 1px solid gray;
  width: 50%;
}

.midPart {
  border: 1px solid gray;
  width: 50%;
}

.leftPart {
  border: 1px solid gray;
  width: 50%;
}

.groupElement {
  cursor: pointer;
  padding: 4px;
  margin: 4px;
  border-radius: 5px;
  display: flex;
  justify-content: space-between;
}

.userElement {
  cursor: pointer;
  padding: 4px;
  margin: 4px;
  background-color: lightgray;
  border-radius: 5px;
  display: flex;
  justify-content: space-between;
}

.memberElement {
  cursor: pointer;
  padding: 4px;
  margin: 4px;
  background-color: lightgray;
  border-radius: 5px;
  display: flex;
  justify-content: space-between;
}

.addNewGroup {
  display: flex;
  padding: 4px;
}

.hourglass {
  position: fixed;
  top: 40%;
  left: 46%;
  font-size: 64px;
  color: gray;
  animation: spin infinite 2s linear;
}

@keyframes spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
.approveButton {
  background-color: #4caf50;
}
.rejectButton {
  background-color: #f44336;
}

.approveButton,
.rejectButton {
  padding: 8px 16px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-size: 0.8em;
  width: 100px;
  color: white;
  font-weight: 700;
  transition: transform 0.2s ease;
}
.approveButton:hover,
.rejectButton:hover {
  transform: scale(0.9);
  background-color: #515151;
}
.licenceImage {
  object-fit: contain;
  max-width: 300px;
  border-radius: 20px;
}
