.homeContainer{
  display: flex;
  flex-direction: column;
  align-items: center;
}

.boxContainer{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-top: 3%;
    width: 80%;
}

.blueBackground{
    background-color: #17A2B8;
  }
  
.greenBackground{
  background-color: #28A745;
}

.yellowBackground{
  background-color: #DD5B32;
}

.redBackground{
  background-color: #dc3545;
}

.slateBackground{
  background-color: slategray;
}

.chartContainer{
  max-width: 100vw;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;
  width: 80%;
}

