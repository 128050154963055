.navMenuGroup {
  /*border-bottom: 1px solid #DDD;*/
}

.navMenu {
  width: 100%;
  display: flex;
  flex-direction: row;
  margin: 0;
  padding: 0;
  font-size: 1.2em;
  -webkit-user-select: none; /* Safari */
  -ms-user-select: none; /* IE 10 and IE 11 */
  user-select: none; /* Standard syntax */
}

.menuitem {
  padding: 8px 10px;
  color: #666;
  text-decoration: none;
  transition: transform 0.2s, color 0.2s;
  cursor: pointer;
  font-size: 0.9em;
}

.menuitem:hover {
  color: #ff4f00;
  transform: translate(0px, 2px);
}

.subMenu {
  position: absolute;
  background-color: #444;
  width: 200px;
  padding-left: 10px;
  margin-top: 5px;
  color: #ddd;
}

.subMenu p:hover {
  color: #fff;
}
