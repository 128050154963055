  .container{
    display: flex;
    justify-content: center;
  }
  
  .container h2{
    color : #ff4f00;
    align-self: center;
  }

  .batteriesList {
    display: flex;
    flex-direction: column;
    align-items: center;   
    width: 1200px;
    border-radius: 0.25rem;
    box-shadow: 0 0 1px rgba(0, 0, 0, 0.125), 0 1px 3px rgba(0, 0, 0, 0.2);
    padding: 1%;
  }
  
  .topBar {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    width: 100%;
  }
  
  .topBar div {
    text-align: center;
  }

  .topBar h3{
    color : #666;
  }
  