body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.App {
  width: 100%;
  height: 100%;
}

html,
body,
.App,
.App > div {
  height: 100vh;
}

.leaflet-container {
  width: 100%;
  height: 100%;
}

.svg-inline--fa {
  margin-left: 0.5em;
  margin-right: 0.5em;
}

button {
  cursor: pointer;
  height: 2.1rem;
  border: none;
  border-radius: 4px;
  font-size: 0.85em;
  min-width: 80px;
  width: 140px;
  color: var(--grayColor);
  background-color: #ddd;
  margin-left: 2px;
  margin-right: 2px;
}

button:hover {
  background-color: #ccc;
}

input {
  height: 2rem;
  border: 1px solid #ddd;
  border-radius: 4px;
  font-size: 1em;
  width: 180px;
  color: var(--grayColor);
  background-color: #fff;
  margin-left: 2px;
  margin-right: 2px;
  padding-left: 4px;
  padding-right: 4px;
  box-sizing: border-box;
  vertical-align: middle;
}

h2 {
  margin: 0px;
  padding: 0px;
}

select {
  height: 2rem;
  border: 1px solid #ddd;
  border-radius: 4px;
  font-size: 1em;
  width: 180px;
  color: var(--grayColor);
  background-color: #fff;
  margin-left: 2px;
  margin-right: 2px;
  box-sizing: border-box;
  vertical-align: middle;
}

hr {
  margin: 0;
  box-shadow: 0 20px 20px -20px #333;
}

:root {
  --grayColor: #666;
  --blueColor: #1a3060;
  --orangeColor: #dd5a2a;
}
