.page {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
}

.login {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 0 80px;
  height: 100%;
  background-color: #fff;
  /*background-image: url("../../../public/images/greengo_wow_login.png");*/
  background-size: 100% 100vh;
  background-position: 100%;
  background-repeat: no-repeat;
  color: #ff4f00;
}

.loginMsg {
  color: #ff4f00;
  font-size: 1.4em;
  background-color: rgba(255, 255, 255, 0.8);
  padding: 4px;
}

@media only screen and (max-device-width: 500px) {
  .login {
    width: 100%;
  }
}

.formBox {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.formBox input {
  padding: 8px;
}

.formBox button {
  font-size: 1.1em;
  margin: 10px;
  border: 1px solid #666;
  border-radius: 8px;
}

.formBox input,
.formBox button {
  font-size: 1.1em;
  margin: 10px;
  border: 1px solid #666;
  width: 100%;
  border-radius: 8px;
  height: 60px;
  min-width: 320px;
}

.formBox input {
  color: #666;
  /*background-color : rgba(0, 0, 0, 0.4);*/
  font-size: 1.4rem;
}

.buttonBox {
  display: flex;
}

.formBox button {
  cursor: pointer;
  /*background-color: rgba(0, 0, 0, 0.4);*/
  font-size: 1.6rem;
  color: #666;
  font-weight: bold;
  transition: color 0.5s;
}

.signUpBox {
  font-size: 13px;
  line-height: 1;
}

.title {
  color: #ff4f00;
  font-size: 3rem;
}

.animationDiv {
  min-height: 40px;
  width: 100%;
}

.bike {
  height: 36px;
  margin-left: 0%;
}

.animation {
  animation-name: bikerun;
  animation-duration: 2s;
  animation-fill-mode: forwards;
}

@keyframes bikerun {
  0% {
    margin-left: 0%;
  }
  100% {
    margin-left: 90%;
  }
}
