.container {
  height: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.innerContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 2rem 1rem;
  box-shadow: 1px 2px 5px rgb(255, 82, 24);
  border-radius: 56px;
}
.inputContainer {
  display: flex;
  flex-direction: column;
}
.column {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.logo {
  width: 300px;
  object-fit: contain;
  padding: 1rem;
}
.header {
  font-size: 1.4rem;
}
.input {
  width: 300px;
  height: 40px;
  margin: 1rem 0;
  border: 1px solid rgb(255, 82, 24);
  padding: 20px;
}
.button {
  background-color: rgb(255, 82, 24);
  color: white;
  width: 140px;
  height: 47px;
  border-radius: 17px;
  justify-self: center;
  align-self: center;
}
.button:hover {
  border: 1px solid rgb(255, 82, 24);
  color: rgb(255, 82, 24);
  background-color: white;
}
