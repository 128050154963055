.exportBar {
  display: flex;
  flex-direction: row;
  justify-content: right;
  gap: 4px;
}

.button {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 8px;
  height: unset;
  padding: 0.5rem 1rem;
  margin: 0 auto;
}

.button[disabled] {
  color: #adb5bd;
  background-color: #f1f3f5;
  cursor: not-allowed;
}
