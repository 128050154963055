.rentabike{
    width : 100%;
    max-width: 600px;
    display: flex;
    flex-direction: column;
    align-items: center;
    border: 1px solid gray;
}

.pageTitle{
    background-color: rgba(0,0,0,0.6);
    color: white;
    width: 600px;
    text-align: center;
    z-index: 1001;
}

.rentabike button{
    color : #EEE;
    background-color: rgba(0,0,0,0.6);
    border : none;
    cursor:pointer;
    border: 1px solid #FFF;
}

.rentabike button:hover{
    color : #DD5A2A;
}

.link{
    cursor : pointer;
}

.link:hover{
    color : #DD5A2A;
}

.mapSection{
    position: fixed;
    z-index: 1;
    width: 100%;
    max-width: 600px;
    height: 800px;
}

.mapButtons{
    width: 100%;
    display: flex;    
    justify-content: space-around;    
    position: absolute;
    bottom: 60px; 
    z-index: 1200;
}

.mapButtons button{
    margin: 0;
    min-width: 180px;
    width : 180px; 
}

.bgImage{
    width: 600px;
    height: 800px;
    position: fixed;
    z-index: 0;
}

/*------------------------------------------------*/

.qrSection{
    width: 600px;
    height: 800px;
    display: flex;
    flex-direction: column;
    align-items: center;  
    color : #FFF;
    background-image: url("../../../public/images/scooter_background.png");
    background-size: 100% 100vh;
    background-position: 100%;
    background-repeat: no-repeat;
}

.qrSection img{
    position: fixed;
}

.qrButtons{    
    width: 100%;
    display: flex;    
    justify-content: space-around;        
    position: absolute;
    bottom: 180px; 
}

.qrButtons button{    
    min-width: 180px;
    width : 180px;
}

/*------------------------------------------------*/

.rentConfirm{
    width: 100%;
    height: 800px;
    color : #FFF;
    background-image: url("../../../public/images/scooter_background.png");
    background-size: 100% 100vh;
    background-position: 100%;
    background-repeat: no-repeat;
}

.confirmInfo{
    margin : 5%;
    background-color: rgba(0,0,0,0.6);
    border-radius: 30px;
    padding: 5%;
}

.rentConfirm p{
    font-size: 1.8em;
}

.rentConfirmButtons{
    position: absolute;
    bottom: 180px;
    width: 600px;
    display: flex;
    justify-content: space-around;    
}

.rentConfirmButtons button{
    margin: 0;
    min-width: 180px;
    width : 180px; 
}

.chargeInfo{
    display: flex;
    flex-direction: row;
    justify-content: left;
    align-items: center;
    gap: 10px;
    font-size: 1.8em;
}

/*------------------------------------------------*/

.chargeLevelContainer{
    width: 200px;
    height: 40px;
    border: 2px solid #FFF;
    border-radius: 8px;
    display: flex;
    justify-content: left;
}

.chargelevel{
    height: 40px;
    background-color: orange;
    border-top-left-radius: 6px;
    border-bottom-left-radius: 6px;
}

.levelText{
    position: relative; 
    top: -30px;
    font-size: 1.2em;
    color : darkblue;
}
/*------------------------------------------------*/
.rentProgress{
    width: 100%;
    height: 800px;
    color : #FFF;
    background-image: url("../../../public/images/scooter_girl_driving.png");
    background-size: contain;
    background-position: 100%;
    background-repeat: no-repeat;
}

.rentProgressButtons{
    position: absolute;
    bottom: 180px;
    width: 600px;
    display: flex;
    justify-content: space-around;    
}

.rentProgress p{
    font-size: 1.8em;
}

.rentProgressButtons button{
    margin: 0;
    min-width: 180px;
    width : 180px; 
}

.progressInfo{
    margin : 5%;
    background-color: rgba(0,0,0,0.6);
    border-radius: 30px;
    padding: 5%;
}