.filterBox{
    display: flex;
    align-items: center;
}

.filterInput{
    border-radius: 4px;
    border: 1px solid #DDD;
    padding: 6px;
    font-size: 1em;
}

.clearFilter{
    cursor: pointer;
    -webkit-user-select: none; /* Safari */
    -ms-user-select: none; /* IE 10 and IE 11 */
    user-select: none; /* Standard syntax */
}

.clearFilter:hover{
    font-weight: bolder;
}