.box {
  width: 22%;
  max-width: 400px;
  height: 160px;
  border-radius: 0.4rem;
  box-shadow: 0 0 1px rgba(0, 0, 0, 0.125), 0 1px 3px rgba(0, 0, 0, 0.2);
  margin-bottom: 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  transition: transform 0.4s;
  cursor: pointer;
}

.box:hover{
  transform : translate(0px, 4px);
}

.boxContainer {
  display: flex;
}

.infoContainer{
  margin: 10px;
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-content: space-around;
}

.valuePart{
  text-align: right;
  width: 100%;
}

.valuePart span{
  font-size: 2.6em;
  color : #EEE;
}

.propertyPart{
  margin-top:16px;
  color:#DDD;
}

.propertyPart span{
  height: 30%;
  font-weight: bold;
}

.iconContainer span{
  font-size: 4.4em;
  color:rgba(255,255,255,0.3);
  position: relative;
  bottom:25%;
}

.infoContainer p{
  margin:0;
  text-align: right;
  color : #DDD;
}