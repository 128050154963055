.userSection {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 10px;
  cursor: pointer;
  color: #666;
}

.logoutIcon {
  font-size: 1.2em;
}

.logoutIcon:hover {
  color: #ff4f00;
}

.userBadge {
  display: flex;
  background-color: #ff4f00;
  border-radius: 28px;
  height: 28px;
  width: 28px;
  color: white;
  font-weight: 400;
  justify-content: center;
  align-items: center;
}