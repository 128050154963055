.container {
  display: flex;
  justify-content: center;
}

.container h2 {
  color: #ff4f00;
  align-self: center;
}

.topBar {
  display: flex;
  padding: 2px 10px 10px 2px;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}

.topBar h3 {
  color: #666;
}

.stationsListandMap {
  display: flex;
  justify-content: center;
  width: 100%;
  padding: 5px;
  gap: 10px;
}

.stationDetails {
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 800px;
  border-radius: 0.25rem;
  box-shadow: 0 0 1px rgba(0, 0, 0, 0.125), 0 1px 3px rgba(0, 0, 0, 0.2);
  padding: 1%;
}

.stationsList {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 1200px;
  border-radius: 0.25rem;
  box-shadow: 0 0 1px rgba(0, 0, 0, 0.125), 0 1px 3px rgba(0, 0, 0, 0.2);
  padding: 1%;
}

.stationDetailsPlugins {
  display: flex;
  flex-direction: column;
}

.stationDetailsGaugeCharts {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
}

.stationsMapContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 70vh;
}

.mapContainer {
  width: 50%;
}

.stationOperation {
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 800px;
  border-radius: 0.25rem;
  box-shadow: 0 0 1px rgba(0, 0, 0, 0.125), 0 1px 3px rgba(0, 0, 0, 0.2);
  padding: 1%;
}

.stationOperationMap {
  width: 100%;
}

.clickable {
  cursor: pointer;
}

.back {
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: flex-end;
}

.button {
  display: flex;
  padding: 0;
  width: 92%;
  margin-top: 10px;
  justify-content: center;
  align-items: center;
  text-align: center;
}
.warning {
  margin: 10px 0;
  padding-right: 15px;
  text-align: center;
  color: #ff4f00;
  font-size: 11px;
}
